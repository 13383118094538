import { message, notification } from 'antd';
import { getRequest, postRequest } from 'app/axiosClient';
import { all, call, put, takeLatest } from 'redux-saga/effects';
//postRequest
import {
  getCategorySectorList,
  getCategorySectorListFailed,
  getCategorySectorListSuccess,
  getCitiesByProvince,
  getCitiesByProvinceFailed,
  getCitiesByProvinceSuccess,
  getDistrictByCity,
  getDistrictByCityFailed,
  getDistrictByCitySuccess,
  getOngoingProject,
  getOngoingProjectFailed,
  getOngoingProjectSuccess,
  getProvinces,
  getProvincesFailed,
  getProvincesSuccess,
  getQuestionDetail,
  getQuestionDetailFailed,
  getQuestionDetailSuccess,
  //GET TEMPLATE
  getTemplateEveryPage,
  // getTemplateEveryPageSuccess,
  getTemplateEveryPageFailed,
  getTemplateEveryPageSuccess,
  postSelectedOption,
  postSelectedOptionFailed,
  postSelectedOptionSuccess,
  //   setPagination,
  postTemplateEveryPage,
  postTemplateEveryPageFailed,
  postTemplateEveryPageSuccess,
  setLoading,
} from './CreateProjectSlice';

import { ERROR_MESSAGE_ID } from 'assets/languages/error-message';
import { showModalAfterSubmitSuccess } from '../../Project/projectSlice';

function* getCategorySectorListAPI(action) {
  yield put(
    setLoading({
      name: 'categoryListPayload',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest('pdd/selection?source=mini_prefs&page=1', true),
    );

    yield put(
      getCategorySectorListSuccess({
        name: 'categoryListPayload',
        data: res.data?.data,
        pageInfo: res.data?.page_info,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Gagal menampilkan metodologi',
    });
    yield put(
      getCategorySectorListFailed({
        name: 'categoryListPayload',
      }),
    );
  }
}

function* getOngoingProjectAPI(action) {
  yield put(
    setLoading({
      name: 'OngoingProject',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.id}/progress`, true),
    );
    yield put(
      getOngoingProjectSuccess({
        name: 'OngoingProject',
        data: res.data,
      }),
    );
  } catch (e) {
    yield put(
      getOngoingProjectFailed({
        name: 'OngoingProject',
      }),
    );
  }
}

function* getProvincesAPI() {
  yield put(
    setLoading({
      name: 'provinces',
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('locations/provinces', true));
    yield put(
      getProvincesSuccess({
        name: 'provinces',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getProvincesFailed({
        name: 'provinces',
      }),
    );
  }
}

function* getCitiesByProvinceAPI(action) {
  yield put(
    setLoading({
      name: 'cities',
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `locations/cities?province_id=${action.payload.province_id}`,
        true,
      ),
    );
    yield put(
      getCitiesByProvinceSuccess({
        name: 'cities',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getCitiesByProvinceFailed({
        name: 'cities',
      }),
    );
  }
}

function* getDistrictByCityAPI(action) {
  yield put(
    setLoading({
      name: 'district',
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`locations/districts?city_id=${action.payload.city_id}`, true),
    );
    yield put(
      getDistrictByCitySuccess({
        name: 'district',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getDistrictByCityFailed({
        name: 'district',
      }),
    );
  }
}

function* getQuestionDetailAPI(action) {
  yield put(
    setLoading({
      name: 'QuestionDetailPayload',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/${action.payload.id}/template/${action.payload.page}`,
        true,
      ),
    );

    yield put(
      getQuestionDetailSuccess({
        name: 'QuestionDetailPayload',
        data: res.data?.components[0],
        animation: res.data?.metadata?.animation_href,
        selected: res.data?.components[0]?.last_value,
        title: res.data.name,
        pageInfo: res.data?.page,
      }),
    );
  } catch (e) {
    yield put(
      getQuestionDetailFailed({
        name: 'QuestionDetailPayload',
        title: e.data.name,
        message: e.data.message,
      }),
    );
  }
}
function* postSelectedOptionAPI(action) {
  yield put(
    setLoading({
      name: 'SelectedOptionPayload',
      status: true,
    }),
  );
  try {
    let res;
    const { history } = action.payload;
    res = yield call(() =>
      postRequest(
        `simple-pdd/${action.payload.id}/template/${action.payload.page}`,
        action.payload.data,
      ),
    );

    yield put(
      postSelectedOptionSuccess({
        SelectedOptionPayload: res.data,
      }),
    );
    if (res.data) {
      if (res.data.next_page !== 0) {
        history.push({
          pathname: '/create-project',
          search: `?id=${res.data.sub_sector_id}&page=${res.data.next_page}`,
        });
      }
    }
  } catch (e) {
    notification['error']({
      message: 'Selected Option Error',
    });
    yield put(
      postSelectedOptionFailed({
        name: 'SelectedOptionPayload',
      }),
    );
  }
}

function* getTemplateEveryPageAPI(action) {
  try {
    const { params } = action.payload;
    const res = yield call(() =>
      getRequest(`simple-pdd/${params.id}/template/${params?.page}`, true),
    );
    yield put(
      getTemplateEveryPageSuccess({
        name: action.payload.name,
        response: {
          data: res?.data,
          pageInfo: res?.data?.page_info,
        },
      }),
    );
  } catch (e) {
    // notification['error']({
    //   message: 'Category List Error',
    // });
    yield put(
      getTemplateEveryPageFailed({
        name: action.payload.name,
        response: {
          data: e.data,
        },
      }),
    );
  }
}

function* postTemplateEveryPageAPI(action) {
  const { params } = action.payload;
  try {
    const res = yield call(() =>
      postRequest(
        `simple-pdd/${params?.id}/template/${params?.page}`,
        action.payload.body,
      ),
    );
    yield put(
      postTemplateEveryPageSuccess({
        data: res.data,
      }),
    );
    if (res.data.is_end_of_page) {
      if (res.data.next_href === null && res.data.next_page === 0) {
        yield put(
          showModalAfterSubmitSuccess({
            name: 'showModalAfterSubmit',
            idProject: res.data.project_id,
            isEligible: res.data.is_eligible,
            showModal: true,
            isLoading: false,
          }),
        );
      }
    }
  } catch (e) {
    let msg = ERROR_MESSAGE_ID.default;

    if (e.data && e.data.error && ERROR_MESSAGE_ID[e.data.error]) {
      msg = ERROR_MESSAGE_ID[e.data['error']];
    }

    message.error(msg, 5);
    yield put(
      postTemplateEveryPageFailed({
        data: e,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([takeLatest(getCategorySectorList, getCategorySectorListAPI)]);
  yield all([takeLatest(getQuestionDetail, getQuestionDetailAPI)]);
  yield all([takeLatest(postSelectedOption, postSelectedOptionAPI)]);
  yield all([takeLatest(getTemplateEveryPage, getTemplateEveryPageAPI)]);
  yield all([takeLatest(getOngoingProject, getOngoingProjectAPI)]);
  yield all([takeLatest(getProvinces, getProvincesAPI)]);
  yield all([takeLatest(getCitiesByProvince, getCitiesByProvinceAPI)]);
  yield all([takeLatest(getDistrictByCity, getDistrictByCityAPI)]);
  yield all([takeLatest(postTemplateEveryPage, postTemplateEveryPageAPI)]);
}
