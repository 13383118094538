import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  experts: [],
  loader: false,
  isSuccess: null,
  pagination: {},
  expertises: [],
  expertDetail: null,
  expertScheduleLoading: false,
  expertSchedule: null,
  expertSchedulePayload: null,
  expertScheduleBook: null,
  reschedule: null,
  expertSuggestion: null,
  expertSurvey: null,
  expertStatusPayment: null,
  expertPayment: null,
  expertBook: null,
  expertBookLoader: false,
  expertMethodologiesLoading: false,
  expertMethodologies: null,
  expertConsultation: null,
  expertConsultationLoading: false,
  showModalSubmit: true,
};

const expertSlice = createSlice({
  name: 'expert',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loader = action.payload || true;
    },
    resetResponse(state, action) {
      state.isSuccess = action.payload || null;
    },
    getExperts(state) {
      state.loader = true;
      state.showModalSubmit = false;
    },
    getExpertises(state) {
      state.loader = true;
    },
    getExpertResponse(state, action) {
      state.loader = false;
      state.experts = action.payload.experts;
      state.isSuccess = action.payload.success;
    },
    getExpertisesResponse(state, action) {
      state.loader = false;
      state.expertises = action.payload.expertises;
      state.isSuccess = action.payload.success;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
    getExpertDetail(state) {
      state.loader = true;
      state.isSuccess = null;
      state.expertSchedule = null;
      state.expertScheduleBook = null;
      state.expertSchedulePayload = null;
    },
    getExpertDetailResponse(state, action) {
      state.loader = false;
      state.expertDetail = action.payload.expertDetail;
      state.isSuccess = action.payload.success;
    },
    getExpertScheduleLoading(state, action) {
      state.expertScheduleLoading = action.payload.expertScheduleLoading;
      state.expertSchedule = null;
      state.expertSchedulePayload = null;
    },
    getExpertSchedule(state) {
      state.expertScheduleLoading = true;
    },
    getExpertScheduleSuccess(state, action) {
      state.expertScheduleLoading = false;
      state.expertSchedule = action.payload.expertSchedule;
    },
    setExpertSchedulePayload(state, action) {
      state.expertSchedulePayload = action.payload;
      state.isSuccess = null;
    },
    resetExpertSchedulePayload(state) {
      state.expertSchedulePayload = null;
      state.isSuccess = null;
    },
    submitExpertScheduleBook(state, action) {
      state.loader = true;
    },
    submitExpertScheduleBookSuccess(state, action) {
      state.loader = false;
      state.expertScheduleBook = action.payload.expertScheduleBook;
    },
    submitExpertRescheduleBook(state, action) {
      state.reschedule = null;
    },
    submitExpertRescheduleBookSuccess(state, action) {
      state.reschedule = action.payload.reschedule;
    },
    submitExpertSuggestion(state, action) {
      state.loader = true;
      state.expertSuggestion = action.payload.expertSuggestion;
      state.isSuccess = null;
    },
    getExpertSuggestionResponse(state, action) {
      state.loader = false;
      state.expertSuggestion = action.payload.expertSuggestion;
      state.isSuccess = action.payload.success;
    },
    getExpertSurvey(state) {
      state.loader = true;
      state.expertSurvey = null;
      state.isSuccess = null;
    },
    submitExpertSurvey(state, action) {
      state.loader = true;
      state.expertSurvey = action.payload.expertSurvey;
      state.isSuccess = null;
    },
    getExpertSurveyResponse(state, action) {
      state.loader = false;
      state.expertSurvey = action.payload.expertSurvey;
      state.isSuccess = action.payload.success;
    },
    getExpertStatusPayment(state) {
      state.loader = true;
      state.expertStatusPayment = null;
      state.isSuccess = null;
    },
    getExpertStatusPaymentResponse(state, action) {
      state.loader = false;
      state.expertStatusPayment = action.payload.expertStatusPayment;
      state.isSuccess = action.payload.success;
    },
    getExpertPayment(state) {
      state.expertPayment = null;
    },
    getExpertPaymentResponse(state, action) {
      state.expertPayment = action.payload.expertPayment;
    },
    getExpertRecomendation(state) {
      state.loader = true;
      state.expertRecomendation = null;
    },
    getExpertRecomendationResponse(state, action) {
      state.loader = false;
      state.expertRecomendation = action.payload;
    },
    bookExpert(state, action) {
      state.bookExpertLoader = true;
      state.bookExpert = null;
    },
    bookExpertResponse(state, action) {
      state.bookExpertLoader = false;
      state.bookExpert = action.payload.expertBook;
    },
    getExpertMethodologies(state, action) {
      state.expertMethodologiesLoading = true;
      state.expertMethodologies = null;
    },
    getExpertMethodologiesResponse(state, action) {
      state.expertMethodologiesLoading = true;
      const methodologyOption = action.payload.expertMethodologies.map(
        (item) => ({
          label: item.name,
          value: item.name,
        }),
      );
      state.expertMethodologies = methodologyOption;
    },
    getExpertConsultation(state, action) {
      state.expertConsultationLoading = true;
      state.expertConsultation = null;
    },
    getExpertConsultationResponse(state, action) {
      state.expertConsultationLoading = false;
      state.expertConsultation = action.payload.expertConsultation;
    },
    getExpertConsultationReset(state) {
      state.expertConsultationLoading = false;
      state.expertConsultation = null;
    },
  },
});

export const {
  getExpertDetail,
  getExpertDetailResponse,
  getExpertises,
  getExpertisesResponse,
  getExperts,
  getExpertResponse,
  setLoading,
  resetResponse,
  setPagination,
  getExpertScheduleLoading,
  getExpertSchedule,
  getExpertScheduleSuccess,
  setExpertSchedulePayload,
  resetExpertSchedulePayload,
  submitExpertScheduleBook,
  submitExpertScheduleBookSuccess,
  submitExpertRescheduleBook,
  submitExpertRescheduleBookSuccess,
  submitExpertSuggestion,
  getExpertSuggestionResponse,
  getExpertSurvey,
  submitExpertSurvey,
  getExpertSurveyResponse,
  getExpertStatusPayment,
  getExpertStatusPaymentResponse,
  getExpertPayment,
  getExpertPaymentResponse,
  getExpertRecomendation,
  getExpertRecomendationResponse,
  bookExpert,
  bookExpertResponse,
  getExpertMethodologies,
  getExpertMethodologiesResponse,
  getExpertConsultation,
  getExpertConsultationResponse,
  getExpertConsultationReset,
} = expertSlice.actions;
export default expertSlice.reducer;
