import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  choose,
  chooseSuccess,
  chooseFailure,
  contactSuccess,
  contactUs,
} from './planSlice';
import { notification } from 'antd';
import { postRequest } from 'app/axiosClient';

function* chooseAPI(action) {
  put(choose());
  try {
    let res;
    res = yield call(() => postRequest('plans/choose', action.payload));
    if (res.data.success) {
      yield put(chooseSuccess(action.payload));
    }
  } catch (error) {
    notification['error']({
      message: 'Failed to choose',
    });
    yield put(chooseFailure());
  }
}

function* contactUsAPI(action) {
  put(choose());
  try {
    let res;
    res = yield call(() => postRequest('pdd/contact-us', action.payload));
    if (res.data) {
      yield put(contactSuccess({ success: true }));
    }
  } catch (error) {
    notification['error']({
      message: 'Failed to contact us',
    });
    yield put(contactSuccess({ success: false }));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(choose, chooseAPI),
    takeLatest(contactUs, contactUsAPI),
  ]);
}
