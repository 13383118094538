import { Grid, Image } from 'antd';
import logo from '../../assets/images/fairatmos-logo.png';
import { HeaderContainer } from './HeaderStyle';
const { useBreakpoint } = Grid;

function Header() {
  const { md, lg } = useBreakpoint();

  return (
    <HeaderContainer
      style={{
        lineHeight: md ? '90px' : '64px',
        height: md ? '90px' : '64px',
        justifyContent: 'center',
      }}
    >
      <a href="/">
        <div className="logo-public">
          <Image width={lg ? 260 : 240} preview={false} src={logo} alt="logo" />
        </div>
      </a>
    </HeaderContainer>
  );
}

export default Header;
