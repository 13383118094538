const MENU_ID = {
  experts: 'Para Ahli',
  consult_list: 'Daftar Konsultasi',
  project_track: 'Daftar Proyek',
  greeting: 'Halo',
  bio: 'Data Diri',
  change_password: 'Kata Sandi',
  profile: 'Profil Saya',
  signout: 'Keluar',
};

export { MENU_ID };
