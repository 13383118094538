const ERROR_MESSAGE_ID = {
  default: 'Terjadi masalah ketika menyimpan data',
  error_jarvis_service_unavailable:
    'Mohon maaf dokumen anda belum bisa kami proses. Lanjutkan dengan menggambar Poligon di Peta',
  error_jarvis_no_area_by_upload:
    'Mohon maaf dokumen anda belum bisa kami proses. Lanjutkan dengan menggambar Poligon di Peta',
  error_jarvis_area_out_of_coverage_by_upload:
    'Mohon maaf dokumen anda belum bisa kami proses. Lanjutkan dengan menggambar Poligon di Peta',
  error_jarvis_area_too_big_by_upload:
    'Mohon maaf dokumen anda belum bisa kami proses. Lanjutkan dengan menggambar Poligon di Peta',
  error_jarvis_area_out_of_coverage_by_polygon:
    'Terjadi kesalahan, area yang Anda gambar masih di luar cakupan wilayah, silakan isi formulir secara manual',
  error_jarvis_no_area_by_polygon:
    'Terjadi kesalahan, area yang Anda gambar masih di luar cakupan wilayah, silakan isi formulir secara manual',
  error_jarvis_area_too_big_by_polygon:
    'Polygon yang anda gambar terlalu besar, silakan isi formulir secara manual',
  error_get_project_reference: 'Detail Proyek Dibatasi',
  error_no_access_data:
    'Akses ditolak, data terkait proyek tidak dapat ditampilkan',
  error_param: 'Nilai input melebihi batas validasi',
  error_old_password_invalid: 'Maaf password lama Anda salah',
};

export { ERROR_MESSAGE_ID };
