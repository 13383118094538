import { useEffect, useRef } from 'react';
import './HamburgerMenuStyle.scss';

function HamburgerMenu(props) {
  const icon1 = useRef(null);
  const icon2 = useRef(null);
  const icon3 = useRef(null);

  const toggleMenu = () => {
    props.onClick(!icon1.current.classList.contains('a'));
  };

  const closeMenu = () => {
    icon1.current.classList.remove('a');
    icon2.current.classList.remove('c');
    icon3.current.classList.remove('b');
  };

  const openMenu = () => {
    icon1.current.classList.add('a');
    icon2.current.classList.add('c');
    icon3.current.classList.add('b');
  };

  useEffect(() => {
    if (props.open) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [props.open]);

  return (
    <div
      data-testid="hamburger-menu"
      className="hamburger-icon"
      id="icon"
      onClick={toggleMenu}
    >
      <div className="icon-1" id="a" ref={icon1}></div>
      <div className="icon-2" id="b" ref={icon2}></div>
      <div className="icon-3" id="c" ref={icon3}></div>
      <div className="clear"></div>
    </div>
  );
}

export default HamburgerMenu;
