import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCreated: '',
  profileLoading: true,
  getProfile: null,
  submitProfileLoading: false,
  submitProfile: null,
  getExpertise: null,
  getExpertiseLoading: false,
  schedule: null,
  scheduleLoading: true,
  avatarListLoading: false,
  avatarList: false,
  bankListLoading: false,
  bankList: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfileLoading(state, action) {
      state.profileLoading = action.payload.profileLoading;
      state.getProfile = null;
    },
    getProfile(state) {
      state.profileLoading = true;
      state.getProfile = null;
    },
    getProfileSuccess(state, action) {
      state.profileLoading = false;
      let profileWithDefaultVal;
      if (action.payload.isCreated === 'Yes') {
        let newData = [];

        if (
          action.payload.getProfile.previous_pdd_experience &&
          action.payload.getProfile.previous_pdd_experience !== ''
        ) {
          if (action.payload.getProfile.previous_pdd_experience.includes(',')) {
            let split =
              action.payload.getProfile.previous_pdd_experience.split(', ');
            split.forEach((e, i) => {
              newData.push({
                label: e,
                value: e,
              });
            });
          } else {
            newData.push({
              label: action.payload.getProfile.previous_pdd_experience,
              value: action.payload.getProfile.previous_pdd_experience,
            });
          }
        }
        action.payload.getProfile.previous_pdd_experience = newData;
        action.payload.getProfile.linkedin_profile =
          action.payload.getProfile.linkedin_profile &&
          action.payload.getProfile.linkedin_profile.replace(
            'https://www.linkedin.com/in/',
            '',
          );
      }

      if (action.payload.role !== 'expert') {
        profileWithDefaultVal = {
          ...action.payload.getProfile,
          honorific: !action.payload.getProfile.honorific
            ? 'Bapak'
            : action.payload.getProfile.honorific,
          contact_country_code: !action.payload.getProfile.contact_country_code
            ? '+62'
            : action.payload.getProfile.contact_country_code,
        };
      } else {
        profileWithDefaultVal = { ...action.payload.getProfile };
      }
      state.getProfile = { ...profileWithDefaultVal };
      state.isCreated = action.payload.isCreated;
    },
    submitProfileLoading(state, action) {
      state.submitProfileLoading = action.payload.submitProfileLoading;
    },
    submitProfile(state) {
      state.profileLoading = true;
    },
    submitProfileSuccess(state, action) {
      state.profileLoading = false;
      state.submitProfile = action.payload.submitProfile;
    },
    submitProfileReset(state, action) {
      state.profileLoading = false;
      state.submitProfile = null;
    },
    getExpertiseLoading(state, action) {
      state.getExpertiseLoading = action.payload.getExpertiseLoading;
    },
    getExpertise(state) {
      state.getExpertiseLoading = true;
    },
    getExpertiseSuccess(state, action) {
      state.getExpertiseLoading = false;
      const expertiseOption = action.payload.getExpertise.map((item) => ({
        label: item.value,
        value: item.id,
        id: item.id,
      }));
      state.getExpertise = expertiseOption;
    },
    getScheduleLoading(state, action) {
      state.scheduleLoading = action.payload.scheduleLoading;
    },
    getSchedule(state) {
      state.scheduleLoading = true;
    },
    getScheduleSuccess(state, action) {
      state.scheduleLoading = false;
      state.schedule = action.payload.schedule;
    },
    getProfileAvatarList(state) {
      state.avatarListLoading = true;
      state.avatarList = null;
    },
    getProfileAvatarListResponse(state, action) {
      state.avatarListLoading = false;
      state.avatarList = action.payload.avatarList;
    },
    getProfileBankList(state) {
      state.bankListLoading = true;
      state.bankList = null;
    },
    getProfileBankListResponse(state, action) {
      state.bankListLoading = false;
      state.bankList = action.payload.bankList;
    },
  },
});

export const {
  getProfileLoading,
  getProfile,
  getProfileSuccess,
  submitProfileLoading,
  submitProfile,
  submitProfileSuccess,
  submitProfileReset,
  getExpertiseLoading,
  getExpertise,
  getExpertiseSuccess,
  getScheduleLoading,
  getSchedule,
  getScheduleSuccess,
  getProfileAvatarList,
  getProfileAvatarListResponse,
  getProfileBankList,
  getProfileBankListResponse,
} = profileSlice.actions;
export default profileSlice.reducer;
