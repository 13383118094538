import { Grid, Layout } from 'antd';
import Header from 'features/Header/Header.js';
const { useBreakpoint } = Grid;

const { Content } = Layout;

function PublicRoutes({ component: Component, hideHeader }) {
  const { lg } = useBreakpoint();
  if (hideHeader) {
    return (
      <Layout style={{ backgroundColor: '#fff' }}>
        <Content>
          <Component />
        </Content>
      </Layout>
    );
  }
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Header />
      <Content style={{ marginTop: lg ? '90px' : '64px' }}>
        <Component />
      </Content>
    </Layout>
  );
}

export default PublicRoutes;
