import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  eligibilityFormLoading,
  methodologyChecklist,
  methodologyChecklistFailed,
  methodologyChecklistLocation,
  methodologyChecklistLocationSuccess,
  methodologyChecklistSubmit,
  methodologyChecklistSubmitFailed,
  methodologyChecklistSubmitSuccess,
  methodologyChecklistSuccess,
  methodologySelection,
  methodologySelectionSubmit,
  methodologySelectionSuccess,
  setPddStep,
} from './eligibilitySlice';
import { getRequest, getRequestPlain, postRequestPlain } from 'app/axiosClient';
import { notification } from 'antd';

function* methodologySelectionAPI(action) {
  yield put(
    eligibilityFormLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('pdd/selection'));

    yield put(
      methodologySelectionSuccess({
        methodologySelection: res.data,
      }),
    );
  } catch (e) {
    methodologySelection({
      formLoading: false,
    });
  }
}

function* methodologySelectionSubmitAPI(action) {
  yield put(
    eligibilityFormLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      postRequestPlain(action.payload.submitUrl, action.payload.data),
    );

    yield put(
      methodologySelectionSuccess({
        methodologySelection: res.data,
      }),
    );
  } catch (e) {
    methodologySelection({
      formLoading: false,
    });
  }
}

function* methodologyChecklistAPI(action) {
  yield put(
    eligibilityFormLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequestPlain(action.payload));

    yield put(
      methodologyChecklistSuccess({
        methodologyChecklist: res.data,
      }),
    );
    yield put(setPddStep(1));
  } catch (e) {
    yield put(
      methodologyChecklistFailed({
        methodologyChecklistMessage: e.data,
      }),
    );
  }
}

function* methodologyChecklistSubmitAPI(action) {
  yield put(
    eligibilityFormLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      postRequestPlain(action.payload.submitUrl, action.payload.data),
    );
    yield put(
      methodologyChecklistSubmitSuccess({
        methodologyChecklistSuccess: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Form Submit Failed',
      description: getErrors(e.data),
    });
    yield put(methodologyChecklistSubmitFailed());
  }
}

function* methodologyChecklistLocationAPI(action) {
  try {
    let res;
    res = yield call(() =>
      getRequestPlain(
        process.env.REACT_APP_HOST_PLAIN_URL + action.payload.targetUrl,
      ),
    );
    yield put(
      methodologyChecklistLocationSuccess({
        methodologyChecklistLocation: res.data,
      }),
    );
  } catch (e) {}
}

const getErrors = (error) => {
  if (!error) return;
  if (error.message) return error.message;
  let messages = '';
  for (const property in error.message) {
    messages = `${messages}${property}: ${error[property].join(', ')}\n`;
  }
  return messages;
};

export default function* rootSaga() {
  yield all([
    takeLatest(methodologySelection, methodologySelectionAPI),
    takeLatest(methodologySelectionSubmit, methodologySelectionSubmitAPI),
    takeLatest(methodologyChecklist, methodologyChecklistAPI),
    takeLatest(methodologyChecklistSubmit, methodologyChecklistSubmitAPI),
    takeLatest(methodologyChecklistLocation, methodologyChecklistLocationAPI),
  ]);
}
