import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectListLoading: true,
  projectList: [],
  projectListStatus: null,
  projectEligibilityLoading: true,
  projectEligibility: null,
  projectEligibilityStatus: null,
  projectEmpty: false,
  projectReferenceLoading: true,
  projectReference: null,
  projectReferenceStatus: null,
  pageValue: 1,
  showModalAfterSubmit: {
    isLoading: true,
    idProject: null,
    isEligible: null,
    showModal: false,
  },
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectListLoading(state, action) {
      state.projectListLoading = action.payload.projectListLoading;
      state.projectList = [];
    },
    projectList(state) {
      state.formLoading = true;
      state.projectReferenceStatus = null;
    },
    projectListSuccess(state, action) {
      state.formLoading = false;
      state.projectList = action.payload.projectList;
      state.projectEmpty = action.payload.projectEmpty;
      state.projectListStatus = 'Success';
    },
    projectListFailed(state, action) {
      state.formLoading = false;
      state.projectListStatus = 'Fail';
    },
    projectEligibilityLoading(state, action) {
      state.projectEligibilityLoading =
        action.payload.projectEligibilityLoading;
    },
    projectEligibility(state) {
      state.projectEligibilityLoading = true;
    },
    projectEligibilitySuccess(state, action) {
      state.projectEligibilityLoading = false;
      state.projectEligibility = action.payload.projectEligibility;
      state.projectEligibilityStatus = 'Success';
    },
    projectEligibilityFailed(state, action) {
      state.projectEligibilityLoading = false;
      state.projectEligibilityStatus = 'Fail';
    },
    projectReferenceLoading(state, action) {
      state.projectReference = action.payload.projectReferenceLoading;
    },
    projectReference(state) {
      state.projectReferenceLoading = true;
    },
    projectReferenceSuccess(state, action) {
      state.projectReferenceLoading = false;
      state.projectReference = action.payload.result;
      state.projectReferenceStatus = 'Success';
      state.showModalAfterSubmit.showModal = false;
    },
    projectReferenceFailed(state) {
      state.projectReferenceLoading = false;
      state.projectReferenceStatus = 'Fail';
    },
    projectReferenceReset(state) {
      state.projectReferenceLoading = true;
      state.projectReferenceStatus = null;
    },
    projectResultDownload(state) {},
    projectResultDownloadSuccess(state, action) {
      state.projectEligibilityLoading = false;
      state.projectResultDownload = action.payload.projectResultDownload;
    },
    showModalAfterSubmitSuccess(state, action) {
      const prevState = state[action.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[action.payload.name] = {
        ...resultPrev,
        ...action.payload,
      };
    },
    setPageValue(state, actions) {
      state.pageValue = actions.payload.page;
    },
  },
});

export const {
  projectListLoading,
  projectList,
  projectListSuccess,
  projectListFailed,
  projectEligibilityLoading,
  projectEligibility,
  projectEligibilityStatus,
  projectEligibilitySuccess,
  projectEligibilityFailed,
  projectResultDownload,
  projectResultDownloadSuccess,
  projectReference,
  projectReferenceLoading,
  projectReferenceSuccess,
  projectReferenceFailed,
  projectReferenceReset,
  showModalAfterSubmitSuccess,
  setPageValue,
} = projectSlice.actions;
export default projectSlice.reducer;
