import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getRequest, getRequestPlain } from 'app/axiosClient';
import { notification } from 'antd';
import {
  projectReference,
  projectReferenceLoading,
  projectReferenceFailed,
  projectReferenceSuccess,
  projectEligibility,
  projectEligibilityFailed,
  projectEligibilityLoading,
  projectEligibilitySuccess,
  projectList,
  projectListFailed,
  projectListLoading,
  projectListSuccess,
  projectResultDownload,
} from './projectSlice';
import FileSaver from 'file-saver';
import { ERROR_MESSAGE_ID } from 'assets/languages/error-message';

function* projectListAPI(action) {
  yield put(
    projectListLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/projects${
          action.payload ? `?${action.payload}&size=10` : ''
        }`,
      ),
    );

    yield put(
      projectListSuccess({
        projectList: res.data,
        projectEmpty: !res?.data?.data?.length,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Gagal menampilkan daftar proyek',
    });
    yield put(projectListFailed());
  }
}

function* projectEligibilityAPI(action) {
  yield put(
    projectEligibilityLoading({
      projectEligibilityLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`pdd/projects/${action.payload.projectId}`),
    );

    yield put(
      projectEligibilitySuccess({
        projectEligibility: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Gagal menampilkan detail proyek',
    });
    yield put(projectEligibilityFailed());
  }
}

function* getProjectReferenceAPI(action) {
  yield put(
    projectReferenceLoading({
      projectReferenceLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.projectId}/references`),
    );

    yield put(
      projectReferenceSuccess({
        result: res.data,
      }),
    );
  } catch (e) {
    let msg = getErrors(e.data);

    if (e.data && e.data.error && ERROR_MESSAGE_ID[e.data.error]) {
      msg = ERROR_MESSAGE_ID[e.data['error']];
    }

    notification['error']({
      message: ERROR_MESSAGE_ID.error_get_project_reference,
      description: msg,
    });
    yield put(projectReferenceFailed());
  }
}

function* projectResultDownloadAPI(action) {
  try {
    let res;
    res = yield call(() => getRequestPlain(action.payload.link));
    FileSaver.saveAs(res.data.data, 'data.jpg');
  } catch (e) {
    yield put(projectEligibilityFailed());
  }
}

const getErrors = (error) => {
  if (!error) return;
  if (error.message) return error.message;
  let messages = '';
  for (const property in error.message) {
    messages = `${messages}${property}: ${error[property].join(', ')}\n`;
  }
  return messages;
};

export default function* rootSaga() {
  yield all([
    takeLatest(projectList, projectListAPI),
    takeLatest(projectEligibility, projectEligibilityAPI),
    takeLatest(projectResultDownload, projectResultDownloadAPI),
    takeLatest(projectReference, getProjectReferenceAPI),
  ]);
}
