import { createSlice } from '@reduxjs/toolkit';

const initialStateCreateProject = {
  loader: false,
  isSuccess: null,
  pagination: {},
  categoryList: [],
  categoryListData: false,
  routeOtherActivity: null,
  categoryListPayload: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    pageInfo: null,
  },
  getPageTwo: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    pageInfo: null,
  },
  QuestionDetailPayload: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    selected: '',
    title: '',
    message: '',
    pageInfo: null,
  },
  getTemplateLayout: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: [],
    selected: '',
    title: '',
    message: '',
    pageInfo: null,
  },
  template: null,
  postTemplate: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
    animation: '',
    selected: '',
    title: '',
    message: '',
    pageInfo: null,
  },
  OngoingProject: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  provinces: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  cities: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  district: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    isFetch: false,
    data: null,
  },
  SelectedOptionPayload: null,
};

const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: initialStateCreateProject,
  reducers: {
    setLoading(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: actions.payload.status,
        data: null,
      };
    },
    resetResponse(state, action) {
      state.isSuccess = action.payload || null;
    },
    setRouteOtherActivity(state, action) {
      state.routeOtherActivity = action.payload;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
    getCategorySectorListSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    },
    getCategorySectorListFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    getCategorySectorList(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isFetch: true,
      };
    },
    getQuestionDetailSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: true,
        isError: false,
      };
    },
    getQuestionDetailFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    getQuestionDetail(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        selected: '',
        title: '',
        animation: '',
        message: '',
        isLoading: true,
        isFetch: true,
      };
    },
    getProvinces(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getProvincesSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getProvincesFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getCitiesByProvince(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getCitiesByProvinceSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getCitiesByProvinceFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getDistrictByCity(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        data: [],
        isLoading: true,
        isFetch: true,
      };
    },
    getDistrictByCitySuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getDistrictByCityFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getOngoingProjectSuccess(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: actions.payload.data,
      };
    },
    getOngoingProjectFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      };
    },
    getOngoingProject(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isSuccess: false,
        isError: false,
        data: null,
        isFetch: true,
      };
    },
    postSelectedOptionSuccess(state, action) {
      state.SelectedOptionPayload = action.payload.SelectedOptionPayload;
    },
    postSelectedOptionFailed(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        ...actions.payload,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    },
    postSelectedOption(state, actions) {
      const prevState = state[actions.payload.name];
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state[actions.payload.name] = {
        ...resultPrev,
        isLoading: true,
        isFetch: true,
      };
    },
    setCategorySectorList(state, action) {
      state.categoryListPayload = action.payload;
      state.isSuccess = null;
    },
    resetCategorySectorList(state, action) {
      state.categoryListPayload = action.payload;
      state.isSuccess = null;
    },
    resetCitiesAndDitrict(state) {
      state.cities.data = [];
      state.district.data = [];
    },

    // GET TEMPLATE EVERY PAGE
    getTemplateEveryPage(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isLoading: true,
          isFetch: true,
        },
      };
    },
    getTemplateEveryPageSuccess(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isSuccess: true,
          isLoading: false,
          isFetch: true,
          ...actions.payload.response,
        },
      };
    },
    getTemplateEveryPageFailed(state, actions) {
      const prevState = state.template;
      const resultPrev = JSON.parse(JSON.stringify(prevState));
      state.template = {
        ...resultPrev,
        [actions.payload.name]: {
          isLoading: false,
          isError: true,
          isSuccess: false,
          isFetch: true,
        },
      };
    },
    // POST TEMPLATE EVERY PAGE
    postTemplateEveryPage(state) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: true,
        isError: false,
        isFetch: true,
        data: null,
      };
    },
    postTemplateEveryPageSuccess(state, actions) {
      state.postTemplate = {
        isSuccess: true,
        isLoading: false,
        isError: false,
        isFetch: true,
        data: actions.payload.data,
        pageInfo: null,
      };
    },
    postTemplateEveryPageFailed(state, actions) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: false,
        isError: true,
        isFetch: true,
        data: null,
        message: actions.payload.data,
        pageInfo: null,
      };
    },
    postTemplateEveryPageReset(state) {
      state.postTemplate = {
        isSuccess: false,
        isLoading: false,
        isError: false,
        isFetch: false,
        data: null,
      };
    },
  },
});

export const {
  getCategorySectorList,
  setLoading,

  setRouteOtherActivity,

  setCategorySectorList,
  getCategorySectorListSuccess,
  getCategorySectorListFailed,

  getQuestionDetail,
  getQuestionDetailSuccess,
  getQuestionDetailFailed,

  getOngoingProject,
  getOngoingProjectSuccess,
  getOngoingProjectFailed,

  getProvinces,
  getProvincesSuccess,
  getProvincesFailed,

  getCitiesByProvince,
  getCitiesByProvinceSuccess,
  getCitiesByProvinceFailed,

  getDistrictByCity,
  getDistrictByCitySuccess,
  getDistrictByCityFailed,

  postSelectedOption,
  postSelectedOptionSuccess,
  postSelectedOptionFailed,

  //GET TEMPLATE EVERY PAGE
  getTemplateEveryPage,
  getTemplateEveryPageSuccess,
  getTemplateEveryPageFailed,

  //POST TEMPLATE EVERT PAGE
  postTemplateEveryPage,
  postTemplateEveryPageSuccess,
  postTemplateEveryPageFailed,
  postTemplateEveryPageReset,

  resetCitiesAndDitrict,
} = createProjectSlice.actions;
export default createProjectSlice.reducer;
