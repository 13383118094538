import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plan: '',
  loader: false,
  isSuccess: false,
  contactSuccess: false,
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    choose(state) {
      state.loader = true;
    },
    chooseSuccess(state, action) {
      state.loader = false;
      state.plan = action.payload.plan;
      state.isSuccess = true;
    },
    chooseClear(state, action) {
      state.plan = '';
      state.isSuccess = false;
    },
    chooseFailure(state) {
      state.loader = false;
      state.plan = '';
      state.isSuccess = false;
    },
    contactUs(state) {
      state.loader = true;
    },
    contactSuccess(state, action) {
      state.loader = false;
      state.contactSuccess = action.payload.success;
    },
  },
});

export const {
  choose,
  chooseSuccess,
  chooseClear,
  chooseFailure,
  contactUs,
  contactSuccess,
} = planSlice.actions;
export default planSlice.reducer;
