import { MENU_ID } from 'assets/languages/menu-id';
import { logout } from 'features/Auth/authenticationSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

const MenuLogout = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(logout());
        if (props.role === 'expert') {
          setTimeout(() => {
            props.history.push('/sign-in');
          }, 700);
        }
      }}
    >
      <span style={{ fontSize: '18px', color: '#343434', fontWeight: 700 }}>
        {MENU_ID.signout}
      </span>
    </div>
  );
};

export default MenuLogout;
