import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formLoading: false,
  methodologySelection: null,
  methodologyChecklist: null,
  methodologyChecklistMessage: null,
  methodologyChecklistLocation: null,
  pddStep: 0,
};

const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    eligibilityFormLoading(state, action) {
      state.formLoading = action.payload.formLoading;
    },
    methodologySelection(state) {
      state.formLoading = true;
      state.pddStep = 0;
    },
    methodologySelectionSubmit(state) {
      state.formLoading = true;
    },
    methodologySelectionSuccess(state, action) {
      state.formLoading = false;
      state.methodologySelection = action.payload.methodologySelection;
      state.methodologyChecklist = null;
      state.methodologyChecklistMessage = null;
      state.methodologyChecklistLocation = null;
    },
    methodologySelectionReset(state) {
      state.formLoading = false;
      state.methodologySelection = null;
      state.methodologyChecklist = null;
      state.methodologyChecklistMessage = null;
      state.methodologyChecklistLocation = null;
    },
    methodologyChecklist(state) {
      state.formLoading = true;
    },
    methodologyChecklistSubmit(state) {
      state.formLoading = true;
    },
    methodologyChecklistSuccess(state, action) {
      state.formLoading = false;
      state.methodologyChecklist = action.payload.methodologyChecklist;
      state.methodologyChecklistStatus = null;
      state.methodologyChecklistMessage = null;
    },
    methodologyChecklistFailed(state, action) {
      state.formLoading = false;
      state.methodologyChecklistMessage =
        action.payload.methodologyChecklistMessage;
      state.methodologyChecklist = null;
      state.methodologySelection = null;
    },
    methodologyChecklistSubmitSuccess(state, action) {
      state.methodologyChecklistSuccess =
        action.payload.methodologyChecklistSuccess;
      state.methodologySelection = null;
      state.methodologyChecklist = null;
      state.methodologyChecklistMessage = null;
      state.methodologyChecklistStatus = 'Success';
    },
    methodologyChecklistSubmitFailed(state, action) {
      state.formLoading = false;
      state.methodologyChecklistStatus = 'Fail';
    },
    methodologyChecklistLocation(state) {},
    methodologyChecklistLocationSuccess(state, action) {
      state.methodologyChecklistLocation =
        action.payload.methodologyChecklistLocation;
    },
    setPddStep(state, action) {
      state.pddStep = action.payload;
    },
  },
});

export const {
  eligibilityFormLoading,
  methodologySelection,
  methodologySelectionSubmit,
  methodologySelectionReset,
  methodologySelectionSuccess,
  methodologyChecklist,
  methodologyChecklistSubmit,
  methodologyChecklistSuccess,
  methodologyChecklistFailed,
  methodologyChecklistSubmitSuccess,
  methodologyChecklistSubmitFailed,
  methodologyChecklistLocation,
  methodologyChecklistLocationSuccess,
  setPddStep,
} = eligibilitySlice.actions;
export default eligibilitySlice.reducer;
