import { Header } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const HeaderContainer = styled(Header)`
  display: flex;
  align-items: center;
  background-color: white;
  position: fixed;
  padding: 0 20px;
  z-index: 1001;
  width: 100%;

  .header {
    padding: 0 20px;
    border-bottom: solid 1px #e8e8e8;
    overflow: auto;
    box-shadow: 0 0 30px #f3f1f1;
  }

  .logo-public {
    margin-top: 30px;
  }

  .header__container {
    width: calc(100% - 290px);
    display: flex;
    justify-content: flex-end;
  }

  .header__container .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .header__container .ant-menu-item a,
  .header__container .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

  .header__container .ant-menu-horizontal {
    border-bottom: none;
    display: flex;
    justify-content: flex-end;
    li {
      height: 60px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      color: #434343;
    }
    .ant-menu-item-only-child::after {
      border-bottom: none;
    }
    .ant-menu-item-selected {
      color: #13c2c2;
      font-weight: 700;
    }
    .ant-menu-item-selected::after {
      border-bottom: 3px solid #13c2c2;
    }
    .ant-menu-item-selected:has(.profile-wrap)::after {
      border-bottom: none;
    }
    .ant-menu-item-only-child:hover {
      color: #434343;
    }
    .ant-menu-item-selected.ant-menu-item-only-child:hover {
      color: #13c2c2;
    }
  }

  .header__container .header_left {
    float: left;
  }

  .header__container .header_right {
    width: 100%;
    float: right;
  }

  .header_drawer .ant-drawer-body {
    padding: 0 !important;
  }

  /* align header of Drawer with header of page */
  .header_drawer .ant-drawer-header {
    padding: 20px 24px !important;
  }

  .ant-layout-header {
    z-index: 1001;
  }

  @media (max-width: 991px) {
    padding-left: 10px;
    .header_left,
    .header_right {
      display: none;
    }
    .ant-layout {
      background: red;
    }

    .header__container .ant-menu-item,
    .header__container .ant-menu-submenu-title {
      padding: 1px 20px;
    }
  }
`;
