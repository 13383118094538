import { Grid, Layout } from 'antd';
import HeaderProtectedPage from 'features/Header/HeaderProtectedPage';
const { useBreakpoint } = Grid;

const { Content } = Layout;

function ProtecedRoutes({ component: Component, hideHeader }) {
  const { lg } = useBreakpoint();
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <HeaderProtectedPage hideMenu={hideHeader} />
      <Content style={{ marginTop: lg ? '90px' : '64px' }}>
        <Component />
      </Content>
    </Layout>
  );
}

export default ProtecedRoutes;
