import { Grid } from 'antd';
import { MENU_ID } from 'assets/languages/menu-id';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MenuLogout from './MenuLogout';
import { MenuContainer } from './MenuStyle';
import ProfileMenu from './ProfileMenu';

const { useBreakpoint } = Grid;

const Menu = ({ onClickMenu, user, isProtectedPage }) => {
  const location = useLocation();
  const history = useHistory();
  const { lg } = useBreakpoint();
  const [role, setRole] = useState();
  const userData = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (userData && userData.roles[0]) {
      setRole(userData.roles[0]);
    }
  }, [userData]);

  const menuItems = [
    {
      key: 'home',
      label: 'Home',
      path: '/',
    },
    // {
    //   key: 'about',
    //   label: 'About',
    //   path: '/about',
    // },
    {
      key: 'profile/schedule',
      label: MENU_ID.consult_list,
      path: '/profile/schedule',
    },
    // {
    //   key: 'contact',
    //   label: 'Contact',
    //   path: '/contact',
    // },
    // {
    //   key: 'sign-up',
    //   label: (
    //     <Button type="primary" onClick={() => history.push('/sign-up')}>
    //       Check Your Eligibility
    //     </Button>
    //   ),
    // },
  ];
  let protectedMenuItems, menuMobile;
  if (role === 'expert') {
    protectedMenuItems = [
      {
        key: 'profile',
        label: <ProfileMenu user={user} />,
        path: '/profile',
      },
    ];
    menuMobile = [
      {
        key: 'greet',
        label: <ProfileMenu user={user} />,
        // path: '/profile',
      },
      {
        key: 'profile/edit-data',
        label: MENU_ID.bio,
        path: '/profile/edit-data',
      },
      {
        key: 'change-password',
        label: MENU_ID.change_password,
        path: '/change-password',
      },
      {
        key: 'sign-in',
        label: <MenuLogout role={role} history={history} />,
        path: '/sign-in',
      },
    ];
  } else {
    protectedMenuItems = [
      {
        key: 'projects',
        label: MENU_ID.project_track,
        path: '/project',
      },
      {
        key: 'profile/schedule',
        label: MENU_ID.consult_list,
        path: '/profile/schedule',
      },
      {
        key: 'profile',
        label: <ProfileMenu user={user} />,
        path: '/profile',
      },
    ];
    menuMobile = [
      {
        key: 'greet',
        label: <ProfileMenu user={user} />,
        // path: '/profile',
      },
      {
        key: 'profile/edit-data',
        label: MENU_ID.bio,
        path: '/profile/edit-data',
      },
      {
        key: 'profile/change-password',
        label: MENU_ID.change_password,
        path: '/profile/change-password',
      },
      {
        key: 'projects',
        label: MENU_ID.project_track,
        path: '/projects',
      },
      {
        key: 'profile/schedule',
        label: MENU_ID.consult_list,
        path: '/profile/schedule',
      },
      {
        key: 'sign-in',
        label: <MenuLogout role={role} history={history} />,
        path: '/sign-in',
      },
    ];
  }

  const handleClickMenu = (active) => {
    onClickMenu();
    let ignorePath = ['profile', 'sign-up', 'sign-in', 'greet'];
    if (ignorePath.includes(active.key)) return;
    history.push(`/${active.key === 'home' ? '' : active.key}`);
  };

  const menuData = () =>
    isProtectedPage ? (lg ? protectedMenuItems : menuMobile) : menuItems;

  const selected = () => {
    const findMenu = menuData().find((item) => {
      if (item.key === 'projects') {
        return location.pathname.includes('project');
      } else {
        return location.pathname === item.path;
      }
    });
    return findMenu ? findMenu.key : '';
  };
  return (
    <>
      {!lg ? (
        <div
          style={{
            position: 'absolute',
            top: '33%',
            left: 0,
            marginLeft: '7%',
            zIndex: 99,
            height: '3px',
            width: '85%',
            borderTop: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        ></div>
      ) : null}
      {role && (
        <MenuContainer
          selectedKeys={selected()}
          mode="horizontal"
          items={menuData()}
          onClick={handleClickMenu}
          child={isProtectedPage ? 2 : 1}
          $ismobile={!lg}
        />
      )}
    </>
  );
};

export default Menu;
