import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { getRequest, postRequest } from 'app/axiosClient';
import {
  bookExpert,
  bookExpertResponse,
  getExpertConsultation,
  getExpertConsultationResponse,
  getExpertDetail,
  getExpertDetailResponse,
  getExpertises,
  getExpertisesResponse,
  getExpertMethodologies,
  getExpertMethodologiesResponse,
  getExpertPayment,
  getExpertPaymentResponse,
  getExpertRecomendation,
  getExpertRecomendationResponse,
  getExpertResponse,
  getExperts,
  getExpertSchedule,
  getExpertScheduleLoading,
  getExpertScheduleSuccess,
  getExpertStatusPayment,
  getExpertStatusPaymentResponse,
  getExpertSuggestionResponse,
  getExpertSurvey,
  getExpertSurveyResponse,
  resetResponse,
  setLoading,
  setPagination,
  submitExpertRescheduleBook,
  submitExpertRescheduleBookSuccess,
  submitExpertScheduleBook,
  submitExpertScheduleBookSuccess,
  submitExpertSuggestion,
  submitExpertSurvey,
} from './expertSlice';

function* expertListAPI(action) {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() => getRequest(`experts/?${action.payload}`, false));

    yield put(
      getExpertResponse({
        experts: res.data.content,
        success: true,
      }),
    );
    yield put(
      setPagination({
        page: res.data.page_info.page,
        size: res.data.page_info.size,
        total_pages: res.data.page_info.total_pages,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert List Error',
    });
    getExpertResponse({
      experts: [],
      success: false,
    });
  }
}
function* expertOptionsAPI() {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() => getRequest('experts/expertises', false));

    yield put(
      getExpertisesResponse({
        expertises: res.data.expertises,
        success: true,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Options Error',
    });
    getExpertisesResponse({
      expertises: [],
      success: false,
    });
  }
}
function* expertDetailAPI(action) {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() => getRequest(`experts/${action.payload}`));

    yield put(
      getExpertDetailResponse({
        expertDetail: res.data.expert,
        success: true,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Detail Error',
    });
    put(
      getExpertDetailResponse({
        expertDetail: null,
        success: false,
      }),
    );
    if (e.status === 404) {
      window.location.href = '/experts';
    }
  }
}
function* expertRecomendationAPI() {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() => getRequest('experts/recommendations'));

    yield put(getExpertRecomendationResponse(res.data.data));
  } catch (e) {
    notification['error']({
      message: 'Expert Recomendation Error',
    });
    yield put(getExpertRecomendationResponse(null));
    if (e.status === 401) {
      window.location.href = '/sign-in';
    }
  }
}

function* expertScheduleAPI(action) {
  yield put(getExpertScheduleLoading(true));
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `experts/${action.payload.userId}/available-schedules/${action.payload.date}`,
      ),
    );
    yield put(
      getExpertScheduleSuccess({
        expertSchedule: res.data?.schedules,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Schedule Error',
    });
  }
}

function* submitExpertScheduleBookAPI(action) {
  yield put(setLoading(true));
  yield put(resetResponse());
  try {
    let res;
    res = yield call(() =>
      postRequest('experts/consultations/book', action.payload.data),
    );

    yield put(
      submitExpertScheduleBookSuccess({
        expertScheduleBook: res.data,
      }),
    );
    notification['success']({
      message: 'Booking Expert Schedule Success',
    });
  } catch (e) {
    put(
      submitExpertScheduleBookSuccess({
        expertScheduleBook: null,
      }),
    );
    notification['error']({
      message: 'Expert Schedule Error',
      description: e?.data?.message,
    });
  }
}

function* submitExpertRescheduleBookAPI(action) {
  yield put(resetResponse());
  try {
    let res;
    res = yield call(() =>
      postRequest(
        `experts/consultations/bookings/${action.payload.booking_id}/schedules/${action.payload.schedule_id}/reschedule`,
        action.payload.data,
      ),
    );
    yield put(
      submitExpertRescheduleBookSuccess({
        reschedule: res.data,
      }),
    );
    notification['success']({
      message: 'Booking Expert Schedule Success',
    });
  } catch (e) {
    notification['error']({
      message: 'Expert Schedule Error',
      description: e?.data?.message,
    });
  }
}

function* submitExpertSuggestionAPI(action) {
  yield getExpertSuggestionResponse({
    expertSuggestion: null,
    success: null,
  });
  try {
    let res;
    res = yield call(() =>
      postRequest(
        `experts/consultations/bookings/${action.payload.bookingId}/schedules/${action.payload.scheduleId}/next-step-suggestions`,
        action.payload.data,
      ),
    );

    yield put(
      getExpertSuggestionResponse({
        expertSuggestion: res.data,
        success: true,
      }),
    );
    notification['success']({
      message: 'Success',
    });
  } catch (e) {
    notification['error']({
      message: 'Error',
      description: e?.data?.message,
    });
    yield getExpertSuggestionResponse({
      expertSuggestion: null,
      success: false,
    });
  }
}

function* expertSurveyAPI(action) {
  yield put(setLoading(true));
  getExpertSurveyResponse({
    expertSurvey: null,
    success: null,
  });
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `experts/consultations/bookings/${action.payload.bookingId}/schedules/${action.payload.scheduleId}`,
      ),
    );
    yield put(
      getExpertSurveyResponse({
        expertSurvey: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Survey Error',
    });
    yield put(
      getExpertSurveyResponse({
        expertSurvey: null,
        success: false,
      }),
    );
    if (e.status === 401) {
      window.location.href = '/sign-in';
    }
  }
}

function* submitExpertSurveyAPI(action) {
  yield put(setLoading(true));
  try {
    let res;
    res = yield call(() =>
      postRequest(
        `experts/consultations/bookings/${action.payload.bookingId}/schedules/${action.payload.scheduleId}/rate`,
        action.payload.data,
      ),
    );

    yield put(
      getExpertSurveyResponse({
        expertSurvey: res.data,
        success: true,
      }),
    );
    notification['success']({
      message: 'Success send survey',
    });
  } catch (e) {
    notification['error']({
      message: 'Error send survey',
      description: e?.data?.message,
    });
    getExpertSurveyResponse({
      expertSurvey: null,
      success: false,
    });
  }
}

function* expertStatusPaymentAPI(action) {
  yield put(setLoading(true));
  yield put(resetResponse());
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `experts/consultations/bookings/${action.payload.bookingId}/payments/status`,
      ),
    );
    yield put(
      getExpertStatusPaymentResponse({
        expertStatusPayment: res.data,
        success: true,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Payment Error',
    });
    yield put(
      getExpertStatusPaymentResponse({
        expertStatusPayment: null,
        success: false,
      }),
    );
    if (e.status === 401) {
      window.location.href = '/sign-in';
    }
  }
}

function* bookExpertAPI(action) {
  try {
    let res;
    res = yield call(() =>
      postRequest('experts/consultations/bookings/summary', action.payload),
    );
    yield put(
      bookExpertResponse({
        expertBook: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Error Book Expert',
      description: e?.data?.message?._schema,
    });
    bookExpertResponse({
      expertBook: null,
    });
  }
}

function* expertPaymentAPI(action) {
  yield put(resetResponse());
  try {
    let res;
    res = yield call(() =>
      postRequest(`experts/consultations/bookings/${action.payload}/payments`),
    );
    yield put(
      getExpertPaymentResponse({
        expertPayment: res.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert Payment Error',
    });
    yield put(
      getExpertPaymentResponse({
        expertPayment: null,
      }),
    );
    if (e.status === 401) {
      window.location.href = '/sign-in';
    }
  }
}

function* getExpertMethodologiesAPI(action) {
  try {
    let res;
    res = yield call(() => getRequest('experts/methodologies'));
    yield put(
      getExpertMethodologiesResponse({
        expertMethodologies: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getExpertMethodologiesResponse({
        expertMethodologies: null,
      }),
    );
    notification['error']({
      message: 'Expert Methodology Error',
    });
  }
}

function* getExpertConsultationAPI(action) {
  try {
    let res;
    res = yield call(() =>
      getRequest(`experts/consultations/${action.payload}/join`),
    );
    if (!res.data.meeting_link) {
      notification['error']({
        message: 'Expert consultation Error, Link not available',
      });
    }
    yield put(
      getExpertConsultationResponse({
        expertConsultation: res?.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: 'Expert consultation Error',
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getExperts, expertListAPI),
    takeLatest(getExpertises, expertOptionsAPI),
    takeLatest(getExpertDetail, expertDetailAPI),
    takeLatest(getExpertSchedule, expertScheduleAPI),
    takeLatest(submitExpertScheduleBook, submitExpertScheduleBookAPI),
    takeLatest(submitExpertRescheduleBook, submitExpertRescheduleBookAPI),
    takeLatest(getExpertSurvey, expertSurveyAPI),
    takeLatest(submitExpertSuggestion, submitExpertSuggestionAPI),
    takeLatest(submitExpertSurvey, submitExpertSurveyAPI),
    takeLatest(getExpertStatusPayment, expertStatusPaymentAPI),
    takeLatest(getExpertPayment, expertPaymentAPI),
    takeLatest(getExpertRecomendation, expertRecomendationAPI),
    takeLatest(bookExpert, bookExpertAPI),
    takeLatest(getExpertMethodologies, getExpertMethodologiesAPI),
    takeLatest(getExpertConsultation, getExpertConsultationAPI),
  ]);
}
