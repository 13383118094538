import { Menu } from 'antd';
import styled from 'styled-components';

export const MenuContainer = styled(Menu)`
  .ant-menu-item:hover,
  .ant-menu-submenu:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-item-open,
  .ant-menu-submenu-open,
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: #414cb2;
  }
  .ant-menu-item::after,
  .ant-menu-submenu::after {
    transition: none;
  }
  .ant-menu-item:nth-last-child(-n + ${(props) => props.child}):hover,
  .ant-menu-submenu:nth-last-child(-n + ${(props) => props.child}):hover,
  .ant-menu-item-active:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-submenu-active:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-item-open:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-submenu-open:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-item-selected:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-submenu-selected:nth-last-child(-n + ${(props) => props.child}) {
    border-bottom: none;
  }
  .ant-menu-item:nth-last-child(-n + ${(props) => props.child}):hover::after,
  .ant-menu-submenu:nth-last-child(-n + ${(props) => props.child}):hover::after,
  .ant-menu-item-active:nth-last-child(-n + ${(props) => props.child})::after,
  .ant-menu-submenu-active:nth-last-child(-n
      + ${(props) => props.child})::after,
  .ant-menu-item-open:nth-last-child(-n + ${(props) => props.child})::after,
  .ant-menu-submenu-open:nth-last-child(-n + ${(props) => props.child})::after,
  .ant-menu-item-selected:nth-last-child(-n + ${(props) => props.child})::after,
  .ant-menu-submenu-selected:nth-last-child(-n
      + ${(props) => props.child})::after {
    border-bottom: none;
  }
  .ant-menu-item:nth-last-child(-n + ${(props) => props.child}),
  .ant-menu-submenu-title:nth-last-child(-n + ${(props) => props.child}) {
    cursor: default;
  }
  .profile-wrap {
    display: flex;
    justify-content: center;
    alignItems: center;
    height: 100%;
    line-height: 15px;
    &.mobile {
      margin-top: 20px;
    }
    .avatar-comp {
      vertical-align: middle;
      background: #13C2C2;
      border: none;
      height: 42px;
      width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      span {
        font-size: 18px;
      }
    }
    .bio-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 13px;
      line-height: 18px;
      min-width: 10rem;
      span {
        font-size: 18px;
        color: #343434;
      }
      .ant-dropdown-trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: -15px;
        .anticon.anticon-down {
          margin-left: 10px;
        }
      }
      .logout {
        height: 22px;
        width: 50px;
      }
    }
  }
  .ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
    display: none;
  }

  @media (max-width: 991px) {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    padding-top: 1.5rem;
    border: 0;
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
      color: #13C2C2;
      font-size: 18px;
      height: 2.1rem;
      margin-bottom: 1.2rem;
      font-weight: 700;
    }
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
      color: #000;
      height: 2.1rem;
      font-size: 18px;
      margin-bottom: 1.2rem;
      font-weight: 700;
    }
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child::after{
      border-bottom: none;
    }
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child::after {
      border-bottom: 2px solid #13C2C2;
      margin-bottom: 5px;
    }
    .ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
      display: none;
    }
    .profile-wrap.mobile {
      margin-top: 3rem;
    }
    .avatar-comp.mobile {
      background: #13C2C2;
      border: none;
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      border-radius: 50%;
      cursor: pointer;
      span {
        font-size: 18px;
      }
    }
    .bio-wrap.mobile {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 15px;
      line-height: 18px;
      div {
        font-size: 18px;
      }
      .logout {
        margin-top: 7px;
        height: 22px;
        width: 50px;
        font-size: 16px;
        font-weight: 500;
      }
      .greet {
        position: absolute;
        top: -15px;
        left: 0px;
      }
    }
  }
`;
